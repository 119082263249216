import {instance} from '@/utils/request.js'
/**
 *  S 用户
 */
/**
 * 微信登录
 * @param code {string} code
 * @returns {*}
 */
export const wxLogin = (code, parentId) => {
    return instance({
        url: `/phone/wx_login?code=${code}&parentId=${parentId}`
    })
}
/**
 *  获取用户基本信息
 * @param openid {string} - 用户唯一标识
 * @returns {*}
 */
export const getUserInfo = (openid) => {
    return instance({
        url: `/phone/${openid}`
    })
}
/**
 * ***************************** E 用户 ********************************************
 */
/**
 ************ S 服务商品 ***********************************************************
 */

/**
 *  获取字典
 * @param {string} dictType - 字典类型
 * @returns {*}
 */
export const getDictList = (dictType) => {
    return instance({
        url: `/phone/getDictList?dictType=${dictType}`
    })
}

/**
 *  获取服务列表
 * @param {object}  params  - 查询参数
 * @param {number} params.pageNum - 当前页面
 * @param {number} params.pageSize - 每页条数
 * @returns {*}
 */
export const getServerList = (params) => {
    return instance({
        url: '/phone/getGoodsList',
        params
    })
}
/**
 *  获取服务详情
 * @param id {string} - id
 * @returns {*}
 */
export const getGoodsDetails = (id) => {
    return instance({
        url: `/phone/getGoodsDetails?id=${id}`,
    })
}
/**
 *  根据商品ID获取sku列表
 * @param goodsId {string} - 商品ID
 * @returns {*}
 */
export const getSkuList = (goodsId) => {
    return instance({
        url: `/phone/getSkuList?goodsId=${goodsId}`
    })
}
/**
 * 获取规格详情
 * @param specId
 * @returns {*}
 */
export const getSpecDetails = (specId) => {
    return instance({
        url: `/phone/getSkuDetails?skuId=${specId}`
    })
}

/**
 ************ E 服务商品 ***********************************************************
 */
////////////// S 订单       ////////////////////////////////////////////////////////////
/**
 *  获取微信jbk授权信息
 * @param url - 公众号网络地址
 * @returns {*}
 */
export const getSignature = (url) => {
    return instance({
        url: `/phone/getConfigParams?url=${url}`
    })
}
/**
 *  添加订单
 * @param {object } data - 参数
 * @param {string} data.goodsId - 商品ID
 * @param {string} data.skuId  - 规格ID
 * @param {number} data.orderNum - 数量
 * @param {number} data.czNumber - 户号
 * @param {string} data.orderDesc - 买家留言
 * @param {string} data.orderType - 订单类型
 * @param {string} data.province - 省
 * @param {string} data.city - 市
 * @returns {*}
 */
export const addOrder = (data) => {
    return instance({
        method: 'post',
        url: '/phone/addOrder',
        data
    })
}
/**
 *  获取我的订单
 * @param {object}  params -参数
 * @param {number} params.pageNum - 页码
 * @param {number} params.pageSize - 每页条数
 * @returns {*}
 */
export const getOrderList = (params) => {
    return instance({
        url: '/phone/getOrderList',
        params
    })
}
////////////// E 订单       ////////////////////////////////////////////////////////////
/**
 ************ S 手机号维护 ***********************************************************
 */
/**
 * 获取用户手机列表
 * @returns {*}
 */
export const getPhoneNumList = () => {
    return instance({
        url: '/phone/getPhoneNumList'
    })
}
/**
 *  添加手机号码
 * @param {object} data  - 提交参数
 * @param { string}  data.phoneNum - 手机号码
 * @param { string}  data.phoneName - 名称
 * @param { string}  data.isp - 服务商
 * @returns {*}
 */
export const addPhoneNum = (data) => {
    return instance({
        method: 'post',
        url: '/phone/addPhoneNum',
        data
    })
}
/**
 *  编辑手机号码
 * @param {object} data  - 提交参数
 * @param { string}  data.phoneNum - 手机号码
 * @param { string}  data.phoneName - 名称
 * @param { string}  data.isp - 服务商
 * @returns {*}
 */
export const updPhoneNum = data => {
    return instance({
        method: 'post',
        url: '/phone/updPhoneNum',
        data
    })
}
/**
 *  删除手机号
 * @param {string} id - id
 * @returns {*}
 */
export const delPhoneNum = (id) => {
    return instance({
        url: `/phone/delPhoneNum?id=${id}`,
    })
}

/**
 ************ E 手机号维护 ***********************************************************
 */
//////////////S 电表编号管理   ////////////////////////////////////////////////////////
/**
 * 获取电表编号列表
 * @returns {*}
 */
export const getHouseNumList = () => {
    return instance({
        url: '/phone/getHouseNumList'
    })
}
/**
 *  添加电表编号
 * @param data {object} - 提交参数
 * @param data.houseNum {string} - 电表编号
 * @param data.provinc {string} -省
 * @param data.city {string} -市
 * @param data.houseName {string} - 户名
 * @returns {*}
 */
export const addHouseNum = (data) => {
    return instance({
        method: 'post',
        url: '/phone/addHouseNum',
        data
    })
}
/**
 *  编辑电表编号
 * @param data {object} - 提交参数
 * @param data.houseNum {string} - 电表编号
 * @param data.provinc {string} -省
 * @param data.city {string} -市
 * @param data.houseName {string} - 户名
 * @returns {*}
 */
export const updHouseNum = (data) => {
    return instance({
        method: 'post',
        url: '/phone/updHouseNum',
        data
    })
}
/**
 *  删除电表编号
 * @param id {string} - id
 * @returns {*}
 */
export const delHouseNum = (id) => {
    return instance({
        url: `/phone/delHouseNum?id=${id}`
    })
}
//////////////E 电表编号管理   ////////////////////////////////////////////////////////
/**
 *  获取粉丝列表
 * @param params
 * @returns {*}
 */
export const getFansList = (params) => {
    return instance({
        url: '/phone/getFansList',
        params
    })
}
/**
 * 获取粉丝订单列表
 * @param params
 * @returns {*}
 */
export const getFansOrder = (params) => {
    return instance({
        url: '/phone/getFansOrder',
        params
    })
}
/**
 *  添加收货地址
 * @param data
 * @returns {*}
 */
export const postAddAddress = (data) => {
    return instance({
        url: '/phone/addAddress',
        method: 'post',
        data
    })
}
/**
 *  获取收货列表
 * @returns {*}
 */
export const getAddressList = () => {
    return instance({
        url: '/phone/getAddressList'
    })
}
/**
 *  删除收货地址
 * @param id
 * @returns {*}
 */
export const delAddres = (id) => {
    return instance({
        url: '/phone/delAddress?AddressId=' + id
    })
}
/**
 * 修改收货地址
 * @param data
 * @returns {*}
 */
export const updAddress = (data) => {
    return instance({
        url: '/phone/updAddress',
        method: 'post',
        data
    })
}
/**
 * 获取拼多多商品
 * @param keyword (关键词，可为空)
 * @param pageNum  (翻页页数，初始为 1)
 * @param listId (翻页使用，第一页为空，之后需要带此参数，参数值从上一页中取)
 * @returns {*}
 */
export const getPddGoodsSearch = ({keyword, pageNum, listId}) => {
    return instance({
        url: '/phone/search',
        method: 'post',
        data: {keyword, pageNum, listId}
    })
}
/**
 *  查询备案信息
 * @returns {*}
 */
export const getPddQuery = () => {
    return instance({
        url: '/phone/query',
        method: 'get',
    })
}
/**
 *  获取转链接
 * @param goodsSign
 * @param searchId
 * @returns {*}
 * @constructor
 */
export const UrlGenerate = ({goodsSign, searchId}) => {
    return instance({
        url: '/phone/UrlGenerate',
        method: 'post',
        data: {
            goodsSign,
            searchId
        }
    })
}
/**
 * 获取个人返佣情况
 * @returns {*}
 */
export const getPdaMoney = () => {
    return instance(
        {
            url: '/phone/getPddMoney'
        }
    )
}
/**
 * 获取拼多多订单
 * @param pageNum
 * @param pageSize
 * @returns {*}
 */
export const getPddOrder = ({pageNum, pageSize}) => {
    return instance({
        url: '/phone/getPddOrder',
        params: {pageNum, pageSize}
    })
}
export const getPddIndexList = ({pageNum, pageSize}) => {
    return instance({
        url: '/phone/recommend',
        method: 'post',
        data: {pageNum: pageNum * pageSize}
    })
}
/**
 *
 * @param data 请求参数
 * @param data.carNo,
 * @param  data.phone: '',
 * @param data.userName: '',
 * @param data.address: ''
 * @returns {*}
 */
export const addEtcOrder = (data) => {
    return instance({
        url: '/phone/addEtcOrder',
        method: "post",
        data
    })
}
