import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores' // 引入状态管理


import 'virtual:svg-icons-register'

// 样式全局使用
import 'vant/lib/index.css'
import './styles/main.scss'

import 'nprogress/nprogress.css'
// new VConsole()
const app = createApp(App)

app.use(router)
app.use(pinia) // 注册状态管理

app.mount('#app')
