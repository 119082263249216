import  {defineStore} from 'pinia'
import  {ref} from 'vue'
import {getHouseNumList} from "../../api";
export  const  useWireStore = defineStore('gt-wire',()=>{
    const  wires = ref([])
    // 获取手机号目录
    const getList =async ()=>{
        const {data}   = await getHouseNumList()
        wires.value = data
        return data
    }
    return {
        wires,
        getList
    }
})
