import axios from 'axios'
import {useUserStore} from '@/stores'
import {showToast} from 'vant'

const baseURL = '/goto'
// 1. 新axios实例，基础配置
const instance = axios.create({
    baseURL: baseURL, timeout: 10000
})

// 2. 请求拦截器，携带token
instance.interceptors.request.use((config) => {
    const store = useUserStore()
    if (store.token&&config.headers) {
        config.headers['token'] = `${store.token}`
       //  config.headers['token'] = 'ohvI169rotnGcrOlwxs4DWvq7gAw'
    }
    return config
}, (err) => Promise.reject(err))

// 3. 响应拦截器，剥离无效数据，401拦截
instance.interceptors.response.use((res) => {
    if (res.data.code !== 0) {
        showToast(res.data.msg || '业务失败')
        return Promise.reject(res.data)
    }
    return res.data
}, (err) => {
    return Promise.reject(err)
})

export {baseURL, instance}
