<script setup>
import {RouterView} from 'vue-router'
window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}

</script>

<template>
    <RouterView v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"/>
      </transition>
    </RouterView>
</template>
<style lang="scss">
.fade-enter-from {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-to {
  transform: translateX(0%);
  opacity: 1;
}

.fade-leave-active, .fade-enter-active {
  transition: all 0.5s ease-out;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

.fade-leave-from {
  transform: translateX(0%);
  opacity: 1;
}

.fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>

