import  {defineStore} from 'pinia'
import  {ref} from 'vue'
import {getPhoneNumList} from "../../api";
export  const  usePhoneStore = defineStore('gt-phone',()=>{
    const phones= ref([])
    // 获取手机号目录
    const getPhoneList =async ()=>{
     const {data}   = await getPhoneNumList()
        phones.value = data
        return data
    }
    return {
        phones,
        getPhoneList
    }
})
