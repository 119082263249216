import {defineStore} from "pinia/dist/pinia";
import {ref} from "vue";


export const useAddressStore = defineStore('gt-address',()=>{
 const address = ref()
    const handelAddressUpdate = (info)=>{
        console.log(111,info)
      address.value  = info
    }
    return {address,handelAddressUpdate}
},)
