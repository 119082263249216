import {defineStore} from 'pinia'
import {ref} from 'vue'
import {getUserInfo, wxLogin} from "@/api";
import {getItem, setItem,removeItem} from '@/utils/storage'


const TOKEN_USER = 'APP_BASE_USER_GOTO_RE'
const TOEKEN_KEY = 'APP_BASE_KEY_GOTO_RE'
const TOKEN_SHARE = "APP_BASE_KEY_SHARE"
export const useUserStore = defineStore('gt-user', () => {
    // 用户信息
    const user = ref(getItem(TOKEN_USER) || {})
    // token
    const token = ref(getItem(TOEKEN_KEY) || '')
    const shareId = ref(getItem(TOKEN_SHARE)||'')
    /**
     *  登录
     * @param code {string} - 获取令牌的code
     * @returns {Promise<void>}
     */
    const handelrLogin = async (code) => {
        const {data} = await wxLogin(code,shareId.value )
        token.value = data
        setItem(TOEKEN_KEY, token.value)
        await handelrUserInfo(data)
        handelDelShare()
    }
    /**
     *  获取用户信息
     * @param openid {string} - 用户唯一标识
     * @returns {Promise<void>}
     */
    const handelrUserInfo = async (openid) => {
        const {data} = await getUserInfo(openid)
        user.value = data
        setItem(TOKEN_USER, user.value)
    }
    const handelDelUser = ()=>{
        user.value = undefined
         removeItem(TOKEN_USER)

    }
    const handelDelToken = ()=>{
        token.value = undefined
        removeItem(TOEKEN_KEY)

    }
    const handelShareId = (share)=>{
        shareId.value =share
        setItem(TOKEN_SHARE, shareId.value)
    }
    const  handelDelShare = ()=>{
        shareId.value = undefined
        removeItem(TOKEN_SHARE)
    }
    return {user, token, shareId,handelrLogin, handelrUserInfo,handelDelUser,handelDelToken,handelShareId,handelDelShare}
},)
